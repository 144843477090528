import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b2d17b5 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _259ae28d = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _66925cac = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _573cfee6 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _265a6f9c = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _3c6a109e = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _ad47a1a6 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _084d26ce = () => interopDefault(import('../src/pages/select-extras.vue' /* webpackChunkName: "pages/select-extras" */))
const _117b99ea = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _7469128a = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _554d6dd5 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _2f2111dc = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _3b3fafe0 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _5d369196 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _c07f350a = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _3637124c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _7b2d17b5,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _259ae28d,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _66925cac,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _573cfee6,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _265a6f9c,
    name: "receipt"
  }, {
    path: "/search",
    component: _3c6a109e,
    name: "search"
  }, {
    path: "/search-booking",
    component: _ad47a1a6,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _084d26ce,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _117b99ea,
    name: "terms-and-conditions"
  }, {
    path: "/unsupported-browser",
    component: _7469128a,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _554d6dd5,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _2f2111dc,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _3b3fafe0,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _5d369196,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _c07f350a,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _3637124c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
